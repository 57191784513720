<template>
  <div class="quiz-section">
    <!-- بنر اصلی -->
    <img src="@/assets/img/health-challenge-banner-min.jpg" alt="بنر" @click="openQuizModal" class="banner" />

    <!-- Modal برای نمایش سوال -->
    <div v-if="isQuizModalOpen" class="modal">
      <div class="modal-content">
        <h3>{{ quizQuestion }}</h3>
        <ul class="list-quz-challenge">
          <li class="ls-quz-challenge" v-for="(option, index) in quizOptions" :key="index">
            <button @click="checkAnswer(index)">{{ option }}</button>
          </li>
        </ul>
        <button class="de-btn-chal" @click="closeModal">انصراف</button>
      </div>
    </div>

    <!-- کامپوننت مدیریت خطاها -->
    <ErrorManager :errors="errors" @clear-errors="clearErrors" />
  </div>
</template>
  
  <script>
  import { ref } from 'vue';
import apiClient from '../apiClient';
import ErrorManager from './ErrorManager.vue'; // وارد کردن کامپوننت ErrorManager

export default {
  name: 'QuizBanner',
  components: {
    ErrorManager, // ثبت کامپوننت
  },
  setup() {
    const isQuizModalOpen = ref(false);
    const quizQuestion = ref('');
    const quizOptions = ref([]);
    const correctAnswer = ref(null);
    const errors = ref([]); // لیست خطاهای فعلی

    const fetchRandomQuiz = async () => {
      try {
        const response = await apiClient.get('/wp-json/wp/v2/health_challenge');
        const randomIndex = Math.floor(Math.random() * response.data.length);
        quizQuestion.value = response.data[randomIndex].title.rendered;
        quizOptions.value = response.data[randomIndex].health_challenge_data.options;
        correctAnswer.value = response.data[randomIndex].health_challenge_data.correct_answer;
      } catch (error) {
        // مدیریت خطاهای دریافتی
        errors.value = [];
        if (error.response?.data?.message) {
          errors.value.push(error.response.data.message);
        } else {
          errors.value.push('خطایی رخ داده است.');
        }
      }
    };

    const openQuizModal = () => {
      fetchRandomQuiz();
      isQuizModalOpen.value = true;
    };

    const checkAnswer = async (selectedOption) => {
      const selectedOptionIndexPlusOne = selectedOption + 1;

      if (selectedOptionIndexPlusOne == correctAnswer.value) {
        alert('جواب درست است! امتیاز شما ثبت شد.');

        // دریافت شناسه کاربر از localStorage
        const authToken = localStorage.getItem('auth_token');
        if (!authToken) {
          errors.value = ['خطا: شناسه کاربر یافت نشد.'];
          return;
        }

        try {
          // ارسال درخواست به API برای افزودن امتیاز
          await addScore(1);

          isQuizModalOpen.value = false; // بستن modal پس از ثبت امتیاز
        } catch (error) {
          // مدیریت خطاهای دریافتی
          errors.value = [];
          if (error.response?.data?.message) {
            errors.value.push(error.response.data.message);
          } else {
            errors.value.push('خطایی رخ داده است.');
          }
        }
      } else {
        alert('جواب اشتباه است!');
      }
    };

    const addScore = async (score) => {
  const authToken = localStorage.getItem('auth_token');

  const response = await apiClient.post('/wp-json/wp/v2/add-score', {
    token: authToken,
    score: score,
  });

  console.log(response.data.message); // نمایش پیام موفقیت در کنسول
};

    const clearErrors = () => {
      errors.value = []; // پاک کردن خطاهای فعلی
    };

    fetchRandomQuiz();

    return {
      isQuizModalOpen,
      quizQuestion,
      quizOptions,
      errors,
      openQuizModal,
      checkAnswer,
      clearErrors,
    };
  },
  methods: {
    closeModal() {
      this.isQuizModalOpen = false;
    },
  },
};
  </script>
  
  <style scoped>
  .banner {
    width: 100%;
    cursor: pointer;
    border-radius: 20px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    min-width: 80%;
  }
  .list-quz-challenge{
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .ls-quz-challenge{
    width: 100%;
  }
  .ls-quz-challenge button{
    width: 100%;
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid #F2A65A;
    color: #F2A65A;
    margin: 5px 0;
    padding: 10px;
  }
  .de-btn-chal{
    background-color: transparent;
    padding: 10px 30px;
    border-radius: 8px;
    border: 1px solid #495057;
    color: #495057;
    margin-top: 10px;
    width: 100%;
  }
  </style>