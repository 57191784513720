<template>
  <AuthModal v-if="!isUserLoggedIn" @login-success="onLoginSuccess"/>
  <div id="app" v-else>
    
    <router-view></router-view> <!-- نمایش صفحات -->
    <BottomMenu /> <!-- منوی پایینی -->
  </div>
</template>

<script>
import BottomMenu from './components/BottomMenu.vue';
import AuthModal from './components/AuthModal.vue';

export default {
  components: {
    AuthModal,
    BottomMenu,
  },
  data() {
    return {
      isUserLoggedIn: !!localStorage.getItem('auth_token'), // بررسی توکن در localStorage
    };
  },
  methods: {
    onLoginSuccess() {
      this.isUserLoggedIn = true; // به‌روزرسانی وضعیت ورود کاربر
    },
    logout() {
      localStorage.removeItem('auth_token'); // حذف توکن
      this.isUserLoggedIn = false; // به‌روزرسانی وضعیت ورود کاربر
    },
  },
};
</script>

<style>
body{
  font-family: 'yekan', sans-serif;
  margin: 0;
  direction: rtl;
  background-color:#303030;
}
input{
  font-family: 'yekan', sans-serif;
}
button{
  font-family: 'yekan', sans-serif;
}
#install-button{
  display: flex !important;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    background-color: #fff;
    padding: 0 10px;
}
#install-button2{
  padding: 5px;
    border: none;
    background-color: #F2A65A;
    border-radius: 10px;
}
.con-install-btn{
  display: flex;
    align-items: center;
}
.con-install-btn img{
  width: 40px;
    height: 40px;
    border-radius: 10px;
    margin-left: 5px;
}

</style>