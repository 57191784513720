<template>
    <div class="video-player-container">
      <!-- Video Player -->
      <video
        v-if="videoUrl"
        ref="videoPlayer"
        :src="videoUrl"
        controls
        class="video-element"
        
      ></video>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      videoUrl: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isPlaying: false,
        isMuted: false,
        volume: 1,
        currentTime: "0:00",
        totalTime: "0:00",
        progress: 0,
      };
    },
    watch: {
      volume(newVal) {
        const video = this.$refs.videoPlayer;
        video.volume = newVal;
        video.muted = newVal === 0;
        this.isMuted = video.muted;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Video Player Container */
  .video-player-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    background-color: #000;
    border-radius: 10px;
    overflow: hidden;
    direction:ltr;
  }
  
  /* Video Element */
  .video-element {
    width: 100%;
    height: auto;
    display: block;
  }
  

  </style>