<template>
    <div class="bottom-menu">
      <div
        v-for="(item, index) in menuItems"
        :key="index"
        class="menu-item"
        :class="{ active: activeIndex === index }"
        @click="navigateTo(item.link, index)"
      >
        <img :src="item.icon" :alt="item.title" class="menu-icon" />
        <span class="menu-title">{{ item.title }}</span>
      </div>
    </div>
  </template>
  
  <script>
  import homeIcon from '@/assets/img/home-angle-2-svgrepo-com.svg';
import botIcon from '@/assets/img/chat-square-check-svgrepo-com.svg';
import trainingIcon from '@/assets/img/star-circle-svgrepo-com.svg';
import challengesIcon from '@/assets/img/brain-illustration-1-svgrepo-com.svg';

  export default {
    data() {
      return {
        activeIndex: 0, // اندیس بخش فعال
        menuItems: [
          {icon: homeIcon, link: '/' },
          {icon: botIcon, link: '/bot' },
          {icon: trainingIcon, link: '/training' },
          {icon: challengesIcon, link: '/challenges' },
        ],
      };
    },
    methods: {
      navigateTo(link, index) {
        this.activeIndex = index; // بروزرسانی بخش فعال
        this.$router.push(link); // هدایت به صفحه‌ی مربوطه
      },
    },
  };
  </script>
  
  <style scoped>
  .bottom-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -2px 50px -15px rgba(0, 0, 0, 0.5);
    padding: 10px 0;
    z-index: 1000;
  }
  
  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: #888;
    padding: 10px 15px 8px 15px;
  }
  .menu-item.active{
    background-color: #f2a65a59;
    border-radius: .5rem; 
    box-shadow: 0 0 30px -5px #F2A65A;
  }
  .menu-item.active img{
    filter: brightness(0) saturate(100%) invert(65%) sepia(71%) saturate(391%) hue-rotate(342deg) brightness(99%) contrast(92%);
  }
  
  .menu-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }
  
  .menu-title {
    font-size: 12px;
  }

  .menu-item img{
    filter: brightness(0) saturate(100%) invert(14%) sepia(5%) saturate(0%) hue-rotate(265deg) brightness(109%) contrast(91%);
  }
  </style>