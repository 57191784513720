<template>
    <div class="home-page" @click="closeMenuIfOpen">
      <HeaderMN/>

      <div class="welcome-section">
        <h2>سلام {{ userInfo.first_name }} عزیز،</h2>
         <p>سگ آرزوهاتو با هم میسازیم</p>
      </div>
  

      
      <!-- Main Content -->
      <main class="content">
        <BannerList/>
        <BannersSec/>
        <BtnMain />
      </main>
    </div>
  </template>
  
  <script>
  import BannerList from '@/components/BannerList.vue';
  import BannersSec from '@/components/BannersSec.vue';
  import BtnMain from '@/components/BtnMain.vue';
  import HeaderMN from '@/components/Header.vue';


  export default {
    name: 'HomePage', // تغییر نام به HomePage
    components: {
    HeaderMN,
    BannerList,
    BannersSec,
    BtnMain,
  },
  computed: {
    userInfo() {
      return this.$store.getters.getUserInfo || {};
    },
  },
  created() {
    this.$store.dispatch('fetchUserInfo'); // دریافت اطلاعات کاربر
  },
};
  </script>
  
  <style scoped>
  /* Style for the entire page */
  .home-page {
    background-color: #303030;
    color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  /* Header */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #303030;
  }
  
  .header-left,
  .header-right {
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
  }
  
  .menu-icon,
  .user-icon {
    width: 24px;
    height: 24px;
  }
  
  .logo {
    height: 50px;
  }
  
  /* Sidebar Menu */
  .sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #303030;
    transition: left 0.3s ease;
    z-index: 1000;
  }
  
  .sidebar.open {
    left: 0;
  }
  
  .menu-list {
    list-style: none;
    padding: 20px;
    margin: 0;
  }
  
  .menu-list li {
    padding: 10px;
    cursor: pointer;
    color: white;
    font-size: 16px;
  }
  
  .menu-list li:hover {
    background-color: #404040;
  }
  
  /* Main Content */
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #EDF2FB;
    border-radius: 30px 30px 0 0;
    padding: 20px 20px 80px 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .content h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .content p {
    font-size: 1.2rem;
  }
  .welcome-section {
  background-color: #303030; /* همرنگ Header */
  color: white;
  text-align: right;
  padding: 20px;
}

.welcome-section h2 {
  margin: 0;
  font-size: 1rem;
  color: white; /* رنگ سفید برای عنوان */
}

.welcome-section p {
  margin: 10px 0 0;
  font-size: 1rem;
  color: #037ABE; /* رنگ آبی برای متن */
}

  </style>