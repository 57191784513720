<template>
    <div class="sub-banners">
      <!-- Skeleton Loading -->
      <div v-if="loading" class="skeleton-container">
        <div class="skeleton-left"></div>
        <div class="skeleton-right"></div>
      </div>
  
      <!-- Error Message -->
      <div v-else-if="error">خطا در دریافت داده‌ها</div>
  
      <!-- Two Side-by-Side Banners -->
      <div v-else class="side-banners">
        <!-- Left Banner: Activity Chart -->
        <div class="left-banner">
          <div class="activity-chart">
    <h3>میزان فعالیت</h3>
    <canvas ref="chartCanvas" height="100px"></canvas>
    <div class="text-chart-des" style="font-size: 0.5rem;">میزان فعالیت به صورت هفتگی می باشد.</div>
  </div>
        </div>
  
        <!-- Right Banner: Image from API -->

        <div v-for="scbndr in secondaryBanner" :key="scbndr.id"  class="right-banner">
            <img @click="navigateToTrainingPage" :src="scbndr.secondary_banner.image" alt="بنر فرعی" />
        </div>
      </div>
    </div>
  </template>
  
 
    <script>
    import apiClient from '../apiClient';
import { Chart, LineController, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';

Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement);
    
    export default {
      data() {
        return {
          secondaryBanner: [], // لیست بنرها  
          loading: true, // وضعیت بارگذاری
          error: false, // وضعیت خطا
          scores: [],
        };
      },
      created() {
        this.fetchSecondaryBanner();
      },
      mounted() {
    this.fetchUserScores();
  },
      methods: {
        async fetchSecondaryBanner() {
          try {
            const response = await apiClient.get('/wp-json/wp/v2/secondary_banner');
            this.secondaryBanner = response.data; // ذخیره داده‌ها
            this.loading = false; // پایان بارگذاری
          } catch (err) {
            console.error('خطا در دریافت داده‌ها:', err);
            this.error = true; // نمایش خطای بارگذاری
            this.loading = false;
          }
        },
        async fetchUserScores() {
      try {
        const authToken = localStorage.getItem('auth_token');

        const response = await apiClient.get('/wp-json/wp/v2/user-scores', {
          params: {
            token: authToken,
          },
        });

        this.scores = response.data.scores;

        // رسم چارت
        this.renderChart();
      } catch (error) {
        console.error('خطا در دریافت امتیازات:', error.response ? error.response.data : error.message);
      }
    },
    renderChart() {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      new Chart(ctx, {
    type: 'line', // نوع چارت
    data: {
        labels: this.scores.map(score => score.date), // تاریخ‌ها
        datasets: [
            {
                label: 'امتیازات روزانه', // عنوان دیتاست
                data: this.scores.map(score => score.score), // امتیازات
                borderColor: '#037ABE', // رنگ خط چارت
                borderWidth: 2, // ضخامت خط
                fill: false, // پر کردن زیر خط غیرفعال
                pointBackgroundColor: '#037ABE', // رنگ نقاط روی خط
                pointRadius: 0,
                tension: 0.4,
            },
        ],
    },
    options: {
        scales: {
            x: {
                display: false, // حذف محور افقی (اعداد پایین)
                grid: {
                    display: false, // حذف خطوط شبکه افقی
                },
            },
            y: {
                display: false, // حذف محور عمودی (اعداد سمت چپ)
                grid: {
                    display: false, // حذف خطوط شبکه عمودی
                },
            },
        },
        plugins: {
            legend: {
                display: false, // حذف لجند (عنوان دیتاست)
            },
        },
    },
});
    },

    navigateToTrainingPage() {
      // هدایت کاربر به صفحه /training
      this.$router.push('/training');
    }

      },
    };
    </script>

  
  
  <style scoped>
  /* Container */
  .sub-banners {
    margin-top: 20px;
  }
  
  /* Side Banners */
  .side-banners {
    display: flex;
    gap: 20px;
  }
  
  .left-banner,
  .right-banner {
    flex: 1; /* دو بنر به صورت مساوی کنار هم قرار می‌گیرند */
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
  }
  
  .left-banner {
    background-color: #f9f9f9;
  }
  
  .right-banner img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
  
  .chart-container {
    min-height: 200px; /* ارتفاع ثابت برای نمودار */
    background-color: #303030;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* Skeleton Loading */
  .skeleton-container {
    display: flex;
    gap: 20px;
  }
  
  .skeleton-left,
  .skeleton-right {
    flex: 1;
    height: 200px;
    background-color: #ccc;
    border-radius: 10px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
  </style>