<template>
    <HeaderMN/>
    <div class="content-challenges">
      <ActivityChart/>
    <RandomBanner/>
    <QuizBanner/>
    </div>
  </template>
  <script>
import HeaderMN from '@/components/Header.vue';
import ActivityChart from '@/components/ActivityChart.vue';
import RandomBanner from '@/components/RandomBanner.vue';
import QuizBanner from '@/components/QuizBanner.vue';

  export default {
    name: 'ChallengesPage',
    components:{
      HeaderMN,
      ActivityChart,
      RandomBanner,
      QuizBanner,
    }
  };
  </script>
  <style>
.content-challenges{
  background-color: #EDF2FB;
  border-radius: 30px 30px 0 0;
  height: 100vh;
  padding: 20px;
}
</style>