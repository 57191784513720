import { createStore } from 'vuex';
import apiClient from './apiClient';

export default createStore({
  state: {
    userInfo: null,
    isLoggedIn: false,
  },
  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_LOGGED_IN(state, status) {
      state.isLoggedIn = status;
    },
  },
  actions: {
    fetchUserInfo({ commit }) {
      const token = localStorage.getItem('auth_token');
      if (!token) {
        commit('SET_LOGGED_IN', false);
        return;
      }

      apiClient.get('/wp-json/wp/v2/user-info', { params: { token } })
        .then(response => {
          commit('SET_USER_INFO', response.data);
          commit('SET_LOGGED_IN', true);
        })
        .catch(error => {
          console.error(error.response ? error.response.data : error.message);
          commit('SET_LOGGED_IN', false);
        });
    },
  },
  getters: {
    getUserInfo: state => state.userInfo,
    isLoggedIn: state => state.isLoggedIn,
  },
});