import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './styles/_fonts.scss';
import axios from 'axios';
import store from './store';

const apiClient = axios.create({
  baseURL: 'https://app.ghaderpanah.com', 
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiClient;

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/wp-content/themes/chat-dog/dist/service-worker.js')
      .then((registration) => {
        console.log('Service Worker Done', registration);
      })
      .catch((error) => {
        console.error('Service Worker Exit', error);
      });
  });
}

createApp(App).use(router).use(store).mount('#app');


