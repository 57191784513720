<template>
    <HeaderMN/>
    <div class="account-page">
      <!-- بخش بالایی: متن و دکمه خروج -->
      <div class="account-header">
        <div class="account-info">
          <p class="hi-user-info">سلام {{ userInfo.first_name }} عزیز</p>
          <p class="do-user-info">سگ آرزوهاتو با هم میسازیم</p>
        </div>
        <div class="logout-section">
          <button @click="confirmLogout">خروج</button>
        </div>
      </div>
      <!-- بخش‌های فعالیت و دانش -->
      <div class="activity-chart">
    <h3>میزان فعالیت</h3>
    <canvas ref="chartCanvas" height="80px"></canvas>
    <div class="text-chart-des">میزان فعالیت به صورت هفتگی می باشد.</div>
  </div>
    </div>
  </template>
  <script>
  import HeaderMN from '@/components/Header.vue';
  import apiClient from '../apiClient';
import { Chart, LineController, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';

Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement);
    

  
  export default {
    components:{
        HeaderMN,
    },
    data() {
      return {
        user: {
          displayName: "", // نام کاربر
          avatarUrl: "", // آدرس تصویر پروفایل
        },
      };
    },
    methods: {
        async fetchUserScores() {
      try {
        const authToken = localStorage.getItem('auth_token');

        const response = await apiClient.get('/wp-json/wp/v2/user-scores', {
          params: {
            token: authToken,
          },
        });

        this.scores = response.data.scores;

        // رسم چارت
        this.renderChart();
      } catch (error) {
        console.error('خطا در دریافت امتیازات:', error.response ? error.response.data : error.message);
      }
    },
    renderChart() {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      new Chart(ctx, {
    type: 'line', // نوع چارت
    data: {
        labels: this.scores.map(score => score.date), // تاریخ‌ها
        datasets: [
            {
                label: 'امتیازات روزانه', // عنوان دیتاست
                data: this.scores.map(score => score.score), // امتیازات
                borderColor: '#037ABE', // رنگ خط چارت
                borderWidth: 2, // ضخامت خط
                fill: false, // پر کردن زیر خط غیرفعال
                pointBackgroundColor: '#037ABE', // رنگ نقاط روی خط
                pointRadius: 0,
                tension: 0.4,
            },
        ],
    },
    options: {
        scales: {
            x: {
                display: false, // حذف محور افقی (اعداد پایین)
                grid: {
                    display: false, // حذف خطوط شبکه افقی
                },
            },
            y: {
                display: false, // حذف محور عمودی (اعداد سمت چپ)
                grid: {
                    display: false, // حذف خطوط شبکه عمودی
                },
            },
        },
        plugins: {
            legend: {
                display: false, // حذف لجند (عنوان دیتاست)
            },
        },
    },
});
    },
      // تأیید خروج از حساب کاربری
      confirmLogout() {
        if (confirm("آیا می‌خواهید از حساب کاربری خارج شوید؟")) {
          this.logout();
        }
      },
      // خروج از حساب کاربری
      logout() {
        localStorage.removeItem("auth_token"); // حذف توکن
        this.$router.push("/login"); // هدایت به صفحه ورود
      },
    },
    computed: {
    userInfo() {
      return this.$store.getters.getUserInfo || {};
    },
  },
    created() {
      this.$store.dispatch('fetchUserInfo');
    },
    mounted() {
    this.fetchUserScores();
  },
  };
  </script>
  <style>
.account-page {
  text-align: center;
  padding: 20px;
}

.account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.account-info {
  text-align: right;
}

.logout-section button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.logout-section button:hover {
  background-color: #cc0000;
}

.profile-picture {
  margin: 20px auto;
  position: relative;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.upload-label {
  background-color: #F2A65A;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
}

.upload-label:hover {
  background-color: #0056b3;
}

.activity-knowledge {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.activity-box,
.knowledge-box {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  width: 45%;
  text-align: center;
}

.activity-box {
  background-color: #e6ffe6;
}

.knowledge-box {
  background-color: #ffe6e6;
}
.hi-user-info{
    color: #fff;
}
.do-user-info{
    color: #037ABE;
}
</style>