<template>
  <HeaderMN />
  <div class="training-plan-page">
    <div v-if="isLoading" class="loading-container">
      <div
        v-for="i in 6" :key="i"
        :class="['loading-box', i % 2 === 0 ? 'right' : 'left']"
      >
        <div class="loading-box-inner"></div>
      </div>
    </div>
    <!-- Training Plan Boxes -->
    <div class="plan-container">
      <div
        v-for="(plan, index) in sortedPlans"
        :key="plan.id"
        :class="['plan-box-wrapper', index % 2 === 0 ? 'left' : 'right']"
      >
        <div class="plan-box" @click="openModal(plan.video)">
          <img :src="plan.icon" alt="Icon" class="plan-icon" />
          <div class="plan-title">{{ plan.title }}</div>
        </div>
      </div>
    </div>

    <!-- Video Modal -->
    <div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <button class="close-button" @click="closeModal"><img src="@/assets/img/close-circle-svgrepo-com.svg"/></button>
        <VideoPlayer :videoUrl="selectedVideo" />
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '../apiClient';
import HeaderMN from '@/components/Header.vue';
import VideoPlayer from "@/components/VideoPlayer.vue";


export default {
  name: 'TrainingPage', 
  data() {
    return {
      plans: [], // لیست پلن‌های آموزشی
      isModalOpen: false, // وضعیت باز بودن Modal
      selectedVideo: '', // لینک ویدیوی انتخاب‌شده
      isLoading: true,
    };
  },
  components:{
    HeaderMN,
    VideoPlayer,
  },
  created() {
    this.fetchTrainingPlans();
  },
  computed: {
    // مرتب‌سازی پلن‌ها بر اساس ترتیب
    sortedPlans() {
      return [...this.plans].sort((a, b) => a.order - b.order);
    },
  },
  methods: {
    async fetchTrainingPlans() {
      this.isLoading = true;
      try {
        const response = await apiClient.get('/wp-json/wp/v2/training_plan');
        this.plans = response.data.map((item) => ({
          id: item.id,
          title: item.title.rendered,
          icon: item.training_plan.icon,
          video: item.training_plan.video,
          order: item.training_plan.order,
        }));
      } catch (error) {
        console.error('خطا در دریافت اطلاعات:', error);
      } finally{
        this.isLoading = false;
      }
    },
    openModal(videoUrl) {
      this.selectedVideo = videoUrl;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.selectedVideo = '';
    },

    
  },

  
};

</script>

<style scoped>
/* Container */
.training-plan-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #EDF2FB;
  border-radius:30px 30px 0 0;
  padding-bottom:80px;
  min-height: 80vh;
}

/* Plan Container */
.plan-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 800px;

}

/* Plan Box Wrapper */
.plan-box-wrapper {
  display: flex;
  justify-content: flex-start; /* سمت چپ */
}

.plan-box-wrapper.right {
  justify-content: flex-end; /* سمت راست */
}

/* Plan Box */
.plan-box {
  display: inline-flex; /* عرض به اندازه محتوا */
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: transform 0.3s ease;
  background-color: #fff;
}

.plan-box:hover {
  transform: scale(1.05);
}

/* Plan Icon */
.plan-icon {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  border-radius:10px;
}

/* Plan Title */
.plan-title {
  font-size: 1rem;
  white-space: nowrap; /* جلوگیری از شکستن متن */
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fff;
  padding: 2px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 3px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index:1111111;
}
.close-button img{
  width:24px;
  height:24px;
  filter: brightness(0) saturate(100%) invert(51%) sepia(43%) saturate(4881%) hue-rotate(329deg) brightness(86%) contrast(100%);
}

/* Loading Container */
.loading-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

/* Loading Box */
.loading-box {
  display: flex;
  justify-content: flex-start; /* سمت چپ */
}

.loading-box.right {
  justify-content: flex-end; /* سمت راست */
}

.loading-box-inner {
  width: 80%;
  height: 60px;
  background-color: #e7e7e7;
  border-radius: 10px;
  animation: pulse 1.5s infinite ease-in-out;
}

/* Pulse Animation */
@keyframes pulse {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
</style>