<template>
    <div class="banner-list">
      <div v-if="loading" class="skeleton-container">
        <div class="skeleton-image"></div>
    </div>
      <div v-else-if="error">خطا در دریافت داده‌ها</div>
      <div v-else class="banners">
        <div v-for="banner in banners" :key="banner.id" class="banner-item">
          <a @click="startChating">
            <img :src="banner.main_banner.image" :alt="banner.title.rendered" />
          </a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import apiClient from '../apiClient';
  
  export default {
    data() {
      return {
        banners: [], // لیست بنرها  
        loading: true, // وضعیت بارگذاری
        error: false, // وضعیت خطا
      };
    },
    created() {
      this.fetchBanners();
    },
    methods: {
      startChating() {
        this.$router.push('/bot')
      },
      async fetchBanners() {
        try {
          const response = await apiClient.get('/wp-json/wp/v2/main_banner');
          this.banners = response.data; // ذخیره داده‌ها
          this.loading = false; // پایان بارگذاری
        } catch (err) {
          console.error('خطا در دریافت داده‌ها:', err);
          this.error = true; // نمایش خطای بارگذاری
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .banner-list {
    width: 100%;
    height: 200px;
  }
  
  .banners {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .banner-item {
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    height: 200px;
    text-align: center;
  }
  
  .banner-item img {
    width: 100%;
    object-fit: cover;
    height: auto;
  }
  
  .skeleton-container{
    width: 100%;
    height: 200px;
  }
  .skeleton-image{
    background-color: #ccc;
    width: 100%;
    height: 200px;
    border-radius: 20px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  @keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
  </style>