<template>
    <div v-if="localMessages.length > 0" class="success-message-container">
      <div v-for="(message, index) in localMessages" :key="index" class="success-message">
        {{ message }}
      </div>
    </div>
  </template>
  
  <script>
  import { ref, watch } from 'vue';
  
  export default {
    props: {
      messages: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    setup(props) {
      const localMessages = ref([...props.messages]); // کپی پراپ به متغیر محلی
  
      const clearMessage = () => {
        setTimeout(() => {
          localMessages.value = []; // پاک کردن پیام‌ها بعد از 5 ثانیه
        }, 5000);
      };
  
      watch(
        () => props.messages,
        (newMessages) => {
          localMessages.value = [...newMessages]; // به‌روزرسانی متغیر محلی با تغییرات پراپ
          if (newMessages.length > 0) {
            clearMessage();
          }
        }
      );
  
      return {
        localMessages,
      };
    },
  };
  </script>
  
  <style scoped>
  .success-message-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
  }
  
  .success-message {
    background: #4caf50; /* رنگ سبز برای موفقیت */
    color: white;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    animation: fadeOut 5s forwards;
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  </style>