<template>
    <div class="full-width-button-container">
      <button class="full-width-button" @click="startTraining">
        همین الان شروع کن
      </button>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      startTraining() {
        this.$router.push('/bot')
      },
    },
  };
  </script>
  
  <style scoped>
  .full-width-button-container {
    
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
  }
  
  .full-width-button {
    font-family: 'yekan', sans-serif; 
    width: 100%;
    padding: 15px;
    font-size: 1.2rem;
    color: #303030;
    background-color: #F2A65A; /* رنگ آبی */
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 0px 30px -5px #F2A65A;
  }
  
  .full-width-button:hover {
    background-color: #F2A65A; /* رنگ تیره‌تر برای حالت هاور */
  }
  </style>