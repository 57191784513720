<template>
  <div v-if="!isUserLoggedIn" class="auth-modal">
    <div class="modal-content">
      <div class="ico-logo-chatdog">
        <img src="../assets/img/chatdog-icon-tr.png" alt="Chatdog">
        <h1>به اولین هوش مصنوعی آموزش سگ خوش آمدید</h1>
      </div>

      <!-- فرم ورود با شماره تماس -->
      <form @submit.prevent="handlePhoneSubmit" v-if="!otpSent && !showRegistrationForm">
        <h3>ورود و ثبـــت نام</h3>
        <input
          class="num-number-phone"
          type="text"
          v-model="phoneNumber"
          placeholder="شماره تماس خود را وارد کنید"
          maxlength="11"
          required
        />
        <button type="submit" :disabled="timer > 0">ادامه {{ timer > 0 ? `(${timer}s)` : '' }}</button>
      </form>

      <!-- فرم ورود با کد OTP -->
      <div v-if="otpSent && !showRegistrationForm">
        <h3>کد ارسال شده رو وارد کنید</h3>
        <div class="otp-fields">
          <input
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            v-for="(digit, index) in otpDigits"
            :key="index"
            v-model="otpDigits[index]"
            maxlength="1"
            @input="focusNextField(index)"
            required
          />
        </div>
        <button class="btn-otp-fields" @click="verifyOTP">تایید کد</button>
        <p v-if="timer > 0">ارسال مجدد کد: {{ timer }} ثانیه</p>
        <button v-else @click="resendOTP">ارسال مجدد کد</button>
      </div>

      <!-- فرم ثبت‌نام -->
      <div v-if="showRegistrationForm">
  <h3>اطلاعات خود را تکمیل کنید:</h3>
  <form @submit.prevent="completeRegistration">
    <input
      type="text"
      v-model="registerData.name"
      placeholder="نام"
      required
    />
    <input
      type="text"
      v-model="registerData.family"
      placeholder="نام خانوادگی"
      required
    />
    <input
      type="email"
      v-model="registerData.email"
      placeholder="ایمیل"
      required
    />
    <button type="submit">ثبت‌نام</button>
  </form>
</div>
    </div>

    <!-- کامپوننت نمایش پیام‌های موفقیت‌آمیز -->
    <SuccessMessage :messages="successMessages" />

    <!-- کامپوننت مدیریت خطاها -->
    <ErrorManager :errors="errors" @clear-errors="clearErrors" />
  </div>
</template>

<script>
import apiClient from '../apiClient';
import SuccessMessage from './SuccessMessage.vue';
import ErrorManager from './ErrorManager.vue';

export default {
  components: {
    SuccessMessage,
    ErrorManager,
  },
  data() {
    return {
      phoneNumber: '', // شماره تماس کاربر
      otpSent: false, // آیا کد OTP ارسال شده است؟
      otpDigits: ['', '', '', ''], // فیلدهای کد OTP
      showRegistrationForm: false, // آیا فرم ثبت‌نام نمایش داده شود؟
      registerData: {
        name: '',
        family: '',
        email: '',
      },
      token: localStorage.getItem('auth_token') || null, // توکن کاربر
      timer: 0, // تایمر برای ارسال مجدد کد OTP
      successMessages: [], // لیست پیام‌های موفقیت‌آمیز
      errors: [], // لیست خطاهای دریافتی
    };
  },
  computed: {
    isUserLoggedIn() {
      return !!this.token; // بررسی وجود توکن
    },
  },
  methods: {
    // ارسال شماره تماس برای دریافت کد OTP
    async handlePhoneSubmit() {
  if (this.phoneNumber.length !== 11 || !/^\d+$/.test(this.phoneNumber)) {
    this.errors = ['شماره تماس باید 11 رقم باشد.'];
    return;
  }
  try {
    const response = await apiClient.post('/wp-json/wp/v2/send-otp', { phone: this.phoneNumber });
    if (response.data.status === 'otp_sent') {
      this.otpSent = true;
      this.startTimer();
    } else if (response.data.status === 'new_user') {
      this.showRegistrationForm = true; // نمایش فرم ثبت‌نام
    } else {
      this.errors = [response.data.message];
    }
  } catch (error) {
    this.errors = [];
    if (error.response?.data?.message) {
      this.errors.push(error.response.data.message);
    } else {
      this.errors.push('خطا در ارسال کد OTP.');
    }
  }
},

    // اعتبارسنجی کد OTP
    async verifyOTP() {
  const otp = this.otpDigits.join('');
  if (otp.length !== 4) {
    this.errors = ['کد OTP باید 4 رقم باشد.'];
    return;
  }
  try {
    const response = await apiClient.post('/wp-json/wp/v2/verify-otp', {
      phone: this.phoneNumber,
      otp,
    });
    if (response.data.status === 'logged_in') {
      this.token = response.data.token;
      localStorage.setItem('auth_token', this.token); // ذخیره توکن در localStorage
      this.successMessages = ['با موفقیت وارد شدید.'];
      this.$emit('login-success'); // اعلام ورود موفق به والد
    } else {
      this.errors = [response.data.message];
    }
  } catch (error) {
    this.errors = [];
    if (error.response?.data?.message) {
      this.errors.push(error.response.data.message);
    } else {
      this.errors.push('خطا در اعتبارسنجی کد OTP.');
    }
  }
},

    // تکمیل فرم ثبت‌نام
async completeRegistration() {
  try {
    // ارسال اطلاعات ثبت‌نام
    const response = await apiClient.post('/wp-json/wp/v2/register-user', {
      phone: this.phoneNumber,
      first_name: this.registerData.name,
      last_name: this.registerData.family,
      email: this.registerData.email,
    });

    if (response.data.status === 'registered') {

      this.showRegistrationForm = false;
      this.otpSent = true;
      // ارسال کد OTP پس از ثبت‌نام
      const otpResponse = await apiClient.post('/wp-json/wp/v2/send-otp', { phone: this.phoneNumber });
      if (otpResponse.data.status === 'otp_sent') {
        this.otpSent = true; // نمایش فرم ورود کد OTP
        this.startTimer();
      } else {
        this.errors = [otpResponse.data.message];
      }
    } else {
      this.errors = [response.data.message];
    }
  } catch (error) {
    this.errors = [];
    if (error.response?.data?.message) {
      this.errors.push(error.response.data.message);
    } else {
      this.errors.push('خطا در ثبت‌نام.');
    }
  }
},

    // حرکت به فیلد بعدی در ورود کد OTP
    focusNextField(index) {
      if (this.otpDigits[index].length === 1 && index < 3) {
        const nextInput = document.querySelector(`input:nth-child(${index + 2})`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    },

    // شروع تایمر برای ارسال مجدد کد OTP
    startTimer() {
      this.timer = 60; // 60 ثانیه
      const interval = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    },

    // ارسال مجدد کد OTP
    async resendOTP() {
      try {
        const response = await apiClient.post('/wp-json/wp/v2/send-otp', { phone: this.phoneNumber });
        if (response.data.status === 'otp_sent') {
          this.startTimer();
        } else {
          this.errors = [response.data.message];
        }
      } catch (error) {
        this.errors = [];
        if (error.response?.data?.message) {
          this.errors.push(error.response.data.message);
        } else {
          this.errors.push('خطا در ارسال مجدد کد OTP.');
        }
      }
    },

    // پاک کردن خطاهای فعلی
    clearErrors() {
      this.errors = [];
    },
  },
};
</script>

<style scoped>
.auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.ico-logo-chatdog img {
  width: 100px;
  margin-bottom: 10px;
}

.num-number-phone {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.otp-fields {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.otp-fields input {
  width: 20%;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  border: 2px solid #F2A65A;
  color: #F2A65A;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

button:disabled {
  background: #ccc;
  cursor: not-allowed;
}
</style>

<style scoped>
.auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 70%;
}

.otp-fields {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 10px;
}

.otp-fields input {
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
}
.ico-logo-chatdog{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ico-logo-chatdog img{
  width: 80px;
  height: 80px;
}
.ico-logo-chatdog h1{
  font-weight: 400;
    font-size: 1rem;
    text-align: center;
}
.modal-content form input{
  width: 100%;
    height: 55px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    padding-inline: 5px;
    text-align: right;
    font-size: 1rem;
    font-weight: 900;
    margin-bottom: 2rem;
}
.num-number-phone{
    text-align: center !important;
    letter-spacing: 8px !important;
}
.num-number-phone::placeholder{
  font-size: 0.8rem;
  color: #959595;
  letter-spacing: normal !important;
}
.modal-content form button , .btn-otp-fields{
  background-color: #F2A65A;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  box-shadow: 0 0 30px 0 #F2A65A;
  border: none;
  margin-top: 1rem;
  color: #303030;
}
</style>