<template>
    <div v-if="errors.length > 0" class="error-modal">
      <div class="modal-content">
        <h2>خطا 🧐</h2>
        <ul>
          <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
        </ul>
        <button @click="clearErrors">فهمیدم</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      errors: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    methods: {
      clearErrors() {
        this.$emit('clear-errors'); // ارسال رویداد به والد برای پاک کردن خطاها
      },
    },
  };
  </script>
  
  <style scoped>
  .error-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .modal-content h2 {
    margin-bottom: 10px;
    color: #ff4d4d;
  }
  
  .modal-content ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .modal-content ul li {
    color: #333;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .modal-content button {
    padding: 10px 20px;
    background: #F2A65A;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  </style>