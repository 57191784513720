<template>
  <div class="activity-chart">
    <h3>میزان فعالیت</h3>
    <canvas ref="chartCanvas" height="80px"></canvas>
    <div class="text-chart-des">میزان فعالیت به صورت هفتگی می باشد.</div>
  </div>
</template>

<script>
import apiClient from '../apiClient';
import { Chart, LineController, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';

Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement);

export default {
  data() {
    return {
      scores: [],
    };
  },
  mounted() {
    this.fetchUserScores();
  },
  methods: {
    async fetchUserScores() {
      try {
        const authToken = localStorage.getItem('auth_token');

        const response = await apiClient.get('/wp-json/wp/v2/user-scores', {
          params: {
            token: authToken,
          },
        });

        this.scores = response.data.scores;

        // رسم چارت
        this.renderChart();
      } catch (error) {
        console.error('خطا در دریافت امتیازات:', error.response ? error.response.data : error.message);
      }
    },
    renderChart() {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      new Chart(ctx, {
    type: 'line', // نوع چارت
    data: {
        labels: this.scores.map(score => score.date), // تاریخ‌ها
        datasets: [
            {
                label: 'امتیازات روزانه', // عنوان دیتاست
                data: this.scores.map(score => score.score), // امتیازات
                borderColor: '#037ABE', // رنگ خط چارت
                borderWidth: 2, // ضخامت خط
                fill: false, // پر کردن زیر خط غیرفعال
                pointBackgroundColor: '#037ABE', // رنگ نقاط روی خط
                pointRadius: 0,
                tension: 0.4,
            },
        ],
    },
    options: {
        scales: {
            x: {
                display: false, // حذف محور افقی (اعداد پایین)
                grid: {
                    display: false, // حذف خطوط شبکه افقی
                },
            },
            y: {
                display: false, // حذف محور عمودی (اعداد سمت چپ)
                grid: {
                    display: false, // حذف خطوط شبکه عمودی
                },
            },
        },
        plugins: {
            legend: {
                display: false, // حذف لجند (عنوان دیتاست)
            },
        },
    },
});
    },
  },
};
</script>
<style>
.activity-chart {
  background-color: #303030;
    padding: 20px;
    border-radius: 20px;
  }
  .activity-chart h3{
    padding: 0;
    margin: 0;
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .text-chart-des{
    color: #ccc;
    font-weight: 400;
    font-size: 0.8rem;
    text-align: center;
    margin-top: 1rem;
  }
</style>