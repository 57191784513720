<template>
  <div class="banner-section">
    <!-- بنر اصلی -->
    <img src="@/assets/img/mental-challenge-banner-min.jpg" alt="بنر" @click="openModal" class="banner" />

    <!-- Modal محتوا -->
    <div v-if="isModalOpen" class="modal">
      <div class="modal-content">
        <img :src="modalContentimg" v-if="modalContentimg" alt="" srcset="" width="100%" />
        <p>{{ modalContentlong }}</p>

        <div class="btn-challenge-container">
          <!-- دکمه انجام دادم -->
          <button class="accept-btn-chal" @click="handleAction">انجام دادم</button>

          <!-- دکمه انصراف -->
          <button class="de-btn-chal" @click="closeModal">انصراف</button>
        </div>
      </div>
    </div>

    <!-- کامپوننت مدیریت خطاها -->
    <ErrorManager :errors="errors" @clear-errors="clearErrors" />
  </div>
</template>
  
<script>
import { ref } from 'vue';
import apiClient from '../apiClient';
import ErrorManager from './ErrorManager.vue'; // وارد کردن کامپوننت ErrorManager

export default {
  name: 'RandomBanner',
  components: {
    ErrorManager, // ثبت کامپوننت
  },
  setup() {
    const isModalOpen = ref(false);
    const modalContent = ref('');
    const modalContentimg = ref('');
    const modalContentlong = ref('');
    const errors = ref([]); // لیست خطاهای فعلی

    const openModal = async () => {
      try {
        const response = await apiClient.get('/wp-json/wp/v2/mental_challenge');
        const randomIndex = Math.floor(Math.random() * response.data.length);
        modalContentimg.value = response.data[randomIndex].mental_challenge_data.image_url;
        modalContentlong.value = response.data[randomIndex].mental_challenge_data.long_text;
        modalContent.value = response.data[randomIndex].title.rendered;
        isModalOpen.value = true;
      } catch (error) {
        // ذخیره پیام‌های خطا
        errors.value = [];
        if (error.response?.data?.message) {
          errors.value.push(error.response.data.message);
        } else {
          errors.value.push('خطایی رخ داده است.');
        }
      }
    };

    const handleAction = async () => {
  try {
    // فراخوانی تابع addScore برای افزودن امتیاز
    await addScore(1); // امتیاز 1 اضافه می‌شود

    // بستن Modal پس از ثبت موفق امتیاز
    isModalOpen.value = false;
  } catch (error) {
    // مدیریت خطاهای دریافتی
    errors.value = [];
    if (error.response?.data?.message) {
      errors.value.push(error.response.data.message);
    } else {
      errors.value.push('خطایی رخ داده است.');
    }
  }
};

async function addScore(score) {
  const authToken = localStorage.getItem('auth_token');

  const response = await apiClient.post('/wp-json/wp/v2/add-score', {
    token: authToken,
    score: score,
  });

  console.log(response.data.message); // نمایش پیام موفقیت در کنسول
}

    const clearErrors = () => {
      errors.value = []; // پاک کردن خطاهای فعلی
    };

    return {
      isModalOpen,
      modalContent,
      modalContentimg,
      modalContentlong,
      errors,
      openModal,
      handleAction,
      clearErrors,
    };
  },
  methods: {
    // متد برای بستن Modal
    closeModal() {
      this.isModalOpen = false;
    },

    // متد برای انجام عملیات (مثال)
    handleActionModal() {
      this.closeModal(); // بعد از انجام عملیات، Modal بسته شود
    },
  },
};
</script>
  
  <style scoped>
  .banner {
    width: 100%;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 20px;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: justify;
  }
  .modal-content img{
    border-radius: 20px;
  }
  .accept-btn-chal{
    background-color: #09bc8a;
    padding: 10px 30px;
    border-radius: 8px;
    border: 1px solid #09bc8a;
    color: #fff;
    margin: 10px;
    box-shadow: 0 0 30px -2px #09bc8a;
    width: 100%;
    flex: 2;
  }
  .de-btn-chal{
    background-color: transparent;
    padding: 10px 30px;
    border-radius: 8px;
    border: 1px solid #495057;
    color: #495057;
    margin: 10px;
    width: 100%;
    flex: 1;
  }
  .btn-challenge-container{
    display: flex
;
    align-items: center;
    justify-content: space-between;
  }
  </style>