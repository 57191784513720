<template>
  <HeaderMN/>
  <ChatBot />
  </template>
  <script>
  import HeaderMN from '@/components/Header.vue';
  import ChatBot from '@/components/ChatBot.vue';

  export default {
    name: 'BotPage', // تغییر نام به BotPage
    components:{
      HeaderMN,
      ChatBot,
    },
  };
  </script>