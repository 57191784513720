import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Bot from '../views/Bot.vue';
import Training from '../views/Training.vue';
import Challenges from '../views/Challenges.vue';
import AccountPage from '@/views/AccountPage.vue';



  const routes = [
    { path: '/', component: Home,},
    { path: '/bot', component: Bot,  },
    { path: '/training', component: Training,  },
    { path: '/challenges', component: Challenges,  },
    { path: '/account', name: 'Account', component: AccountPage,  },
  ];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
