<template>
    <div class="header-container" @click="closeMenuIfOpen">
    <header class="header">
      <div class="header-left">
        <img :src="menuIcon" alt="منو" class="menu-icon" :class="{ change: isMenuOpen }"  @click.stop="toggleMenu" />
      </div>
      <div class="header-center">
        <img src="@/assets/img/logo.png" alt="لوگو" class="logo" />
      </div>
      <div class="header-right">
        <img src="@/assets/img/user-icon.svg" alt="حساب کاربری" class="user-icon" @click="goToProfile" />
      </div>
    </header>

          <!-- Sidebar Menu -->
          <aside class="sidebar" :class="{ open: isMenuOpen }" @click.stop>
        <ul class="menu-list">
          <li><a href="https://ghaderpanah.com/" target="_blank">محمد قادرپناه</a></li>
          <li><a href="https://ghaderpanah.com/product-category/dog-training/" target="_blank">دوره های آموزشی</a></li>
          <li><a href="https://wa.me/989027676661?text=%D8%B3%D9%84%D8%A7%D9%85%D8%8C%20%D9%85%D9%86%20%D9%86%DB%8C%D8%A7%D8%B2%20%D8%A8%D9%87%20%D9%85%D8%B1%D8%A8%DB%8C%20%D9%88%20%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87%20%D8%A2%D9%85%D9%88%D8%B2%D8%B4%DB%8C%20%D8%AF%D8%A7%D8%B1%D9%85" target="_blank">محمد قادرپناه</a></li>
        </ul>
      </aside>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HeaderMN',
    data() {
    return {
      isMenuOpen: false, // حالت اولیه منو (بسته)
    };
  },
    methods: {
      toggleMenu() {
        // منو را باز یا بسته کنید
        this.isMenuOpen = !this.isMenuOpen;
      },
      goToProfile() {
        this.$router.push({ name: 'Account' });
      },
    },
    computed: {
  menuIcon() {
    return this.isMenuOpen ? require('../assets/img/close-square-svgrepo-com.svg') : require('../assets/img/menu-icon.svg');
  },
},
  };
  </script>
  
  <style scoped>
  /* Header */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #303030;
  }
  
  .header-left,
  .header-right {
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
  }
  
  .menu-icon,
  .user-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  .menu-icon.change {
  transform: rotate(180deg);
  opacity: 0.8;
}
  
  .logo {
    height: 50px;
  }
  
  /* Sidebar Menu */
  .sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #303030;
    transition: left 0.3s ease;
    z-index: 1000;
  }
  
  .sidebar.open {
    left: 0;
  }
  
  .menu-list {
    list-style: none;
    padding: 20px;
    margin: 0;
  }
  
  .menu-list li {
    padding: 10px;
    cursor: pointer;
    color: white;
    font-size: 16px;
  }
  .menu-list li a{
    text-decoration: none;
    color: #ffffff;
  }
  .menu-list li:hover {
    background-color: #404040;
  }
  </style>